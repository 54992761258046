import React from 'react';
import { connect } from 'react-redux';
import { StickyContainer } from 'react-sticky';
import { PreauthFooterV3 } from '@zola/zola-ui/src/components/PreAuthV3';
import cx from 'classnames';
import { useLocationForVendorSRPLink } from '@zola/zola-ui/src/hooks/useLocationForVendorSRPLink/useLocationForVendorSRPLink';
import '@zola/zola-ui/src/components/Footer/Footer.less';

import './MainLayout.less';

import { useEffectOnce } from '@zola/zola-ui/src/hooks/useEffectOnce';
import { AppDispatch, RootState } from '@/store';
import { UserContext } from '@zola-helpers/client/dist/es/@types';
import { triggerSrpLinkExperimentViewed } from '@/components/util/triggerSrpLinkExperimentViewed';
import experimentFlags, { CLP_LP_LINKS_TO_SRP } from '@/util/experimentFlags';
import {
  PrimaryNavIdentifier,
  SecondaryNavIdentifier,
} from '@zola-helpers/client/dist/es/constants/navConstants';
import * as NavActions from '../../../../actions/NavActions';
import UnifiedNavV2, { NAV_DATA } from '../../header/UnifiedNavV2';

type MainLayoutProps = {
  id?: string;
  children?: React.ReactNode;
  getCategories: () => void;
  categoriesLoaded: boolean;
  activePrimaryLink?: PrimaryNavIdentifier;
  activeSecondaryLink?: SecondaryNavIdentifier;
  activeTertiaryLink?: SecondaryNavIdentifier;
  disablePrimaryNavCollapse?: boolean;
  disableSecondary?: boolean;
  isWeddingBoutiquePage?: boolean;
  userContext?: UserContext | null;
  user: {
    isSpoof?: boolean;
    firstName?: string;
    lastName?: string;
  };
  useMinHeight?: boolean;
};

const MainLayout = (props: MainLayoutProps) => {
  const {
    id = '',
    children = null,
    activePrimaryLink = NAV_DATA.PRIMARY.REGISTRY,
    activeSecondaryLink,
    activeTertiaryLink,
    disablePrimaryNavCollapse,
    disableSecondary = false,
    isWeddingBoutiquePage,
    userContext,
    user = {
      isSpoof: false,
      firstName: '',
      lastName: '',
    },
    useMinHeight = false,
    categoriesLoaded,
    getCategories,
  } = props;

  useEffectOnce(() => {
    if (!categoriesLoaded) {
      getCategories();
    }
  });
  const isGuest = userContext ? Boolean(userContext.is_guest) : true;
  const isInSRPExperiment =
    typeof window !== 'undefined' &&
    userContext &&
    !userContext.is_guest &&
    experimentFlags.isUnderTest(CLP_LP_LINKS_TO_SRP, userContext, false);
  const weddingLocation = useLocationForVendorSRPLink({
    isInSRPExperiment: isInSRPExperiment || false,
  });

  return (
    <div className={cx({ postAuthNav3PageBackground: !isGuest })}>
      <div className={cx({ postAuthNav3PageWrapper: !isGuest, pageMinHeight: useMinHeight })}>
        <StickyContainer id={id} className="unSticky">
          <UnifiedNavV2
            showPromo
            activePrimaryLink={activePrimaryLink}
            activeSecondaryLink={activeSecondaryLink}
            activeTertiaryLink={activeTertiaryLink}
            disablePrimaryNavCollapse={disablePrimaryNavCollapse}
            disableSecondary={disableSecondary}
            isWeddingBoutiquePage={isWeddingBoutiquePage}
          />
          {user.isSpoof && (
            <div className="spoof-header">
              <h1>
                Logged in as {user.firstName} {user.lastName}
              </h1>
            </div>
          )}
          {isGuest ? children : <div className="isPostAuthNav3Shown">{children}</div>}
        </StickyContainer>
        <div className={cx({ isPostAuthNav3Shown: !isGuest })}>
          <PreauthFooterV3
            weddingLocation={weddingLocation}
            isGuest={isGuest}
            marketplacePostAuthLinkOnClick={() => {
              triggerSrpLinkExperimentViewed(userContext);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { userContext } = state.user;

  return {
    categoriesLoaded: state.nav.categoriesLoaded,
    user: {
      isSpoof: userContext?.is_spoof,
      firstName: userContext?.first_name,
      lastName: userContext?.last_name,
    },
    userContext,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCategories: () => dispatch(NavActions.getCategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
